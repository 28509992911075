import React from 'react';
import Helmet from 'react-helmet';
import { Link as GatsbyLink } from 'gatsby';
import Layout from '../components/layout';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { HTMLContent } from '../components/content';
import Container from '@material-ui/core/Container';
import Hero from '../components/hero';
import BonusInfo from 'components/bonus-info';
import CheckCircle from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  feature: {
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'end',
    padding: '2px 0',
  },
  featureIcon: {
    color: theme.palette.secondary,
    marginRight: 4,
  },
  chip: {
    height: 'auto',
    padding: '2px 0px',
    margin: '4px 8px 2px 0',
  },
}));

const CompanyPageTemplate = ({ data }) => {
  // const postLinks = posts.map(post => (
  //   <li key={post.node.fields.slug}>
  //     <Link to={post.node.fields.slug}>
  //       <h2 className="is-size-2">{post.node.frontmatter.title}</h2>
  //     </Link>
  //   </li>
  // ));
  const classes = useStyles();

  const {
    title,
    image,
    features,
    bonus,
    description,
  } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <section className="section">
        <Helmet title={title} />
        <main>
          <Hero title={title} content={bonus.instructions} image={image} />
          <div className={classes.heroUnit}>
            <div className={classes.heroContent}>
              <BonusInfo bonus={bonus} />
              <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      href={bonus.link}
                    >
                      Gå till sida
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      component={GatsbyLink}
                      color="primary"
                      to="/"
                    >
                      Tillbaka
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <Container maxWidth="md">
            <Typography variant="h4" paragraph>
              Om {title}
            </Typography>
            <HTMLContent content={data.markdownRemark.html} />
            <div>
              <Typography variant="subtitle2" gutterBottom>
                <ul style={{ marginBottom: 8 }}>
                  {features &&
                    features.map((feature, i) => (
                      <li className={classes.feature} key={i}>
                        <CheckCircle
                          className={classes.featureIcon}
                          color="secondary"
                        />{' '}
                        {feature}
                      </li>
                    ))}
                </ul>
              </Typography>
            </div>
          </Container>
        </main>
      </section>
    </Layout>
  );
};

export default CompanyPageTemplate;

export const CompanyPageQuery = graphql`
  query CompanyPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      rawMarkdownBody
      fields {
        slug
      }
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        features
        bonus {
          deposit
          money
          instructions
          odds
          turnover
          days
          link
          extra
        }
      }
    }
  }
`;
